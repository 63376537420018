
export const LoginComponent = {
  selector: 'login',
  template: require('./login.component.html'),
  controller: class LoginController {
    constructor( $state) {
      'ngInject';
	  this._$state = $state;
	  this.loginModel ={};
	  this.userList = [{
        email: 'user@email.com',
        password: 'secret'
      }];
    }

    $onInit() {
      
    }
	
	loginHandler() {
		console.log( this.loginModel.username );
		console.log( this.loginModel.password );
		this._$state.go('calendario');
    }
  }
};
