import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import angularLoadingBar from 'angular-loading-bar';
import angularMaterial from 'angular-material';


import ngAnimate from 'angular-animate';
import _ from 'lodash';

// bootstrap
import { RootComponent } from './root.component';

import 'angular-material/angular-material.css';

import angularUi from 'angular-ui-bootstrap';
import angularBootstrapCalendar from 'angular-bootstrap-calendar';

// modules
import { CommonModule } from './common/common.module';
import './root.component.scss';

import { calendarioColision } from './common/directives/calendarioColision/calendarioColision.directive';
  
const MODULE_NAME = 'root';
const MODULE_IMPORTS = [
  uiRouter,
  angularLoadingBar,
  ngAnimate,
  CommonModule,
  angularUi,
  angularBootstrapCalendar,
  angularMaterial
];

angular
  .module(MODULE_NAME, MODULE_IMPORTS)
  .component(RootComponent.selector, RootComponent)
  .value('EventEmitter', payload => ({ $event: payload }))
  .run(($transitions, cfpLoadingBar) => {
    'ngInject';

    $transitions.onStart({}, cfpLoadingBar.start);
    $transitions.onSuccess({}, cfpLoadingBar.complete);
  })
  .name;
