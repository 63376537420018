export default

[
{
 NUMBER_STRING : "CH014491",
 BS_SCORE : "BS_SCORE",
 RISK_ASSESSMENT : 1,
 BRIEF_DESCRIPTION : "GOLF -Copiar INIs y .bat para conexion produccion GOLF MV",
 BS_AFECTA_CAJEROS : 0,
 BS_AFECTA_CANALES : 0,
 BS_AFECTA_COMS : 0,
 BS_AFECTA_CPD : 0,
 BS_AFECTA_MAINFRAME : 1,
 BS_AFECTA_MIAMI : 0,
 BS_AFECTA_MIDRANGE : 0,
 BS_AFECTA_RED_COMERCIAL : 0,
 BS_AFECTA_SEGURIDAD : 1,
 BS_AFECTA_SEGUROS : 0,
 BS_AFECTA_TESORERIA : 0,
 BS_AFECTA_TSB : 0,
 BS_AFECTA_MEXICO : 0,
 BS_AFECTA_OFEX : 0,
 BS_AFECTA_INMOBILIARIA : 0, 
 CI_DOWN : 0,
 BS_ENTORNO : 0,
 title:"GOLF",
 startsAt : new Date("2018-12-21 13:00:00.0"),
 endsAt : new Date("2018-12-21 15:00:00.0"),
 draggable: false,
 resizable: false,
 color: { primary: '#e3bc08', secondary: '#fdf1ba' }
},
{
 NUMBER_STRING : "CH014494",
 BS_SCORE : "BS_SCORE",
 RISK_ASSESSMENT : 1,
 BRIEF_DESCRIPTION : "CORREO - Dar de Alta en ML el dominio tabimed.com",
 BS_AFECTA_CAJEROS : 1,
 BS_AFECTA_CANALES : 1,
 BS_AFECTA_COMS : 0,
 BS_AFECTA_CPD : 0,
 BS_AFECTA_MAINFRAME : 0,
 BS_AFECTA_MIAMI : 0,
 BS_AFECTA_MIDRANGE : 0,
 BS_AFECTA_RED_COMERCIAL : 0,
 BS_AFECTA_SEGURIDAD : 0,
 BS_AFECTA_SEGUROS : 0,
 BS_AFECTA_TESORERIA : 0,
 BS_AFECTA_TSB : 0,
 BS_AFECTA_MEXICO : 0,
 BS_AFECTA_OFEX : 0,
 BS_AFECTA_INMOBILIARIA : 0, 
 CI_DOWN : 0,
 BS_ENTORNO : 0,
 title:"CORREO",
 startsAt : new Date("2018-12-21 12:00:00.0"),
 endsAt : new Date("2018-12-21 13:00:00.0"),
 draggable: false,
 resizable: false,
 color: { primary: '#e3bc08', secondary: '#fdf1ba' }
},
{
 NUMBER_STRING : "CH014493",
 BS_SCORE : "BS_SCORE",
 RISK_ASSESSMENT : 1,
 BRIEF_DESCRIPTION : "Produccion CAM - Ampliar la capacidad de Servicio SDI para la integracion de la CAM con Host de Formacion CAM",
 BS_AFECTA_CAJEROS : 0,
 BS_AFECTA_CANALES : 0,
 BS_AFECTA_COMS : 0,
 BS_AFECTA_CPD : 0,
 BS_AFECTA_MAINFRAME : 0,
 BS_AFECTA_MIAMI : 0,
 BS_AFECTA_MIDRANGE : 0,
 BS_AFECTA_RED_COMERCIAL : 0,
 BS_AFECTA_SEGURIDAD : 0,
 BS_AFECTA_SEGUROS : 0,
 BS_AFECTA_TESORERIA : 0,
 BS_AFECTA_TSB : 0,
 BS_AFECTA_MEXICO : 0,
 BS_AFECTA_OFEX : 0,
 BS_AFECTA_INMOBILIARIA : 0, 
 CI_DOWN : 0,
 BS_ENTORNO : 0,
 title:"Produccion CAM",
 startsAt : new Date("2018-12-22 08:00:00.0"),
 endsAt : new Date("2018-12-22 10:00:00.0"),
 draggable: false,
 resizable: false,
 color: { primary: '#e3bc08', secondary: '#fdf1ba' }
},
{
 NUMBER_STRING : "CH014542",
 BS_SCORE : "BS_SCORE",
 RISK_ASSESSMENT : 3,
 BRIEF_DESCRIPTION : "IOP SHP Produccion webapp canalbs project.canalbs.proteo.bancsabadell.com",
 BS_AFECTA_CAJEROS : 1,
 BS_AFECTA_CANALES : 0,
 BS_AFECTA_COMS : 0,
 BS_AFECTA_CPD : 0,
 BS_AFECTA_MAINFRAME : 0,
 BS_AFECTA_MIAMI : 0,
 BS_AFECTA_MIDRANGE : 0,
 BS_AFECTA_RED_COMERCIAL : 0,
 BS_AFECTA_SEGURIDAD : 0,
 BS_AFECTA_SEGUROS : 0,
 BS_AFECTA_TESORERIA : 0,
 BS_AFECTA_TSB : 0,
 BS_AFECTA_MEXICO : 0,
 BS_AFECTA_OFEX : 0,
 BS_AFECTA_INMOBILIARIA : 0, 
 CI_DOWN : 0,
 BS_ENTORNO : 0,
 title:"IOP SHP",
 startsAt : new Date("2018-12-22 08:00:00.0"),
 endsAt : new Date("2018-12-25 12:00:00.0"),
 draggable: false,
 resizable: false,
 color: { primary: '#e3bc08', secondary: '#fdf1ba' }
},
{
 NUMBER_STRING : "CH014530",
 BS_SCORE : "BS_SCORE",
 RISK_ASSESSMENT : 5,
 BRIEF_DESCRIPTION : "NETWORKING - Apertura de reglas de Firewall (Lunes)",
 BS_AFECTA_CAJEROS : 1,
 BS_AFECTA_CANALES : 0,
 BS_AFECTA_COMS : 0,
 BS_AFECTA_CPD : 0,
 BS_AFECTA_MAINFRAME : 0,
 BS_AFECTA_MIAMI : 0,
 BS_AFECTA_MIDRANGE : 0,
 BS_AFECTA_RED_COMERCIAL : 0,
 BS_AFECTA_SEGURIDAD : 1,
 BS_AFECTA_SEGUROS : 0,
 BS_AFECTA_TESORERIA : 0,
 BS_AFECTA_TSB : 0,
 BS_AFECTA_MEXICO : 0,
 BS_AFECTA_OFEX : 0,
 BS_AFECTA_INMOBILIARIA : 0, 
 CI_DOWN : 0,
 BS_ENTORNO : 0,
 title:"NETWORKING",
 startsAt : new Date("2018-12-24 10:00:00.0"),
 endsAt : new Date("2018-12-24 14:00:00.0"),
 draggable: false,
 resizable: false,
 color: 'red'
},
{
 NUMBER_STRING : "CH014526",
 BS_SCORE : "BS_SCORE",
 RISK_ASSESSMENT : 1,
 BRIEF_DESCRIPTION : "XXL - BSInternet - F5 MZ_BT Creacion de nueva vlan y particion",
 BS_AFECTA_CAJEROS : 1,
 BS_AFECTA_CANALES : 0,
 BS_AFECTA_COMS : 0,
 BS_AFECTA_CPD : 0,
 BS_AFECTA_MAINFRAME : 0,
 BS_AFECTA_MIAMI : 0,
 BS_AFECTA_MIDRANGE : 0,
 BS_AFECTA_RED_COMERCIAL : 0,
 BS_AFECTA_SEGURIDAD : 0,
 BS_AFECTA_SEGUROS : 0,
 BS_AFECTA_TESORERIA : 0,
 BS_AFECTA_TSB : 0,
 BS_AFECTA_MEXICO : 0,
 BS_AFECTA_OFEX : 0,
 BS_AFECTA_INMOBILIARIA : 0, 
 CI_DOWN : 0,
 BS_ENTORNO : 0,
 title:"XXL - BSInternet",
 startsAt : new Date("2018-12-25 10:00:00.0"),
 endsAt : new Date("2018-12-25 15:00:00.0"),
 draggable: false,
 resizable: false,
 color: { primary: '#e3bc08', secondary: '#fdf1ba' }
}
]
