import { default as jsonData } from './example.js';

export default class EventService {
	constructor(AppConstants, $http) {
		'ngInject';
	
		this._AppConstants = AppConstants;
		this._$http = $http;

		// Object to store our user properties
		this.current = null;
		this.root = '/event';
	}
	
	test(){
		console.log("test");
	}
	
	loadDummy(){
		return jsonData;
	}
	
	// Try to authenticate by registering or logging in
	list(type, credentials) {
		let route = '/list';
		return this._$http({
		url: this._AppConstants.api + this.root + route,
		method: 'POST',
		data: {
			user: credentials
		}
    }).then(
      // On success...
      (res) => {
        // Store the user's info for easy lookup
        this.current = res.data.user;

        return res;
      }
    );
  }
  
}