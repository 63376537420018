import html from './calendarioColision.directive.html';
import 'angular-bootstrap-calendar/dist/css/angular-bootstrap-calendar.css'

export default angular
    .module('common')
    .directive('calendarioColision', calendarioColision);

function calendarioColision(moment, calendarConfig){
    return {
        template: html,
        controller($scope){
			var vm = $scope;

			//this is the even to check
			vm.events = [
			  {
				title: 'Event 1',
				color: calendarConfig.colorTypes.warning,
				startsAt: moment().startOf('month').toDate(),
				incrementsBadgeTotal: false
			  },
			  {
				title: 'Event 2',
				color: calendarConfig.colorTypes.info,
				startsAt: moment().startOf('month').toDate(),
				incrementsBadgeTotal: false
			  }
			];
			
			vm.cellModifier = function(cell) {
			  console.log(cell);
			  if (cell.label % 2 === 1 && cell.inMonth) {
				cell.cssClass = 'has-conflict';
			  }
			  cell.label = '-' + cell.label + '-';
			};

			vm.calendarView = 'month';
			vm.viewDate = moment().startOf('month').toDate();
			
			
        }
    }
}