import angular from 'angular';

// containers
import { AppComponent } from './containers/app/app.component';


// components
import { AppNavComponent } from './components/app-nav/app-nav.component';
import { LoginComponent } from './components/login/login.component';
import { CalendarioComponent } from './components/calendario/calendario.component';

//services 
import UserService from './services/user/user.service';

// styles
import './components/app-nav/app-nav.component.scss';

const MODULE_NAME = 'common';
const MODULE_IMPORTS = [];

export const CommonModule = angular
  .module(MODULE_NAME, MODULE_IMPORTS)
  .service('UserService', UserService)
  .component(AppComponent.selector, AppComponent)
  .component(LoginComponent.selector, LoginComponent)
  .component(CalendarioComponent.selector, CalendarioComponent)
  .component(AppNavComponent.selector, AppNavComponent)
  .config(($stateProvider, $locationProvider, $urlRouterProvider) => {
    'ngInject';

    $stateProvider
	   .state(LoginComponent.selector, {
        url: `/${LoginComponent.selector}`,
        component: LoginComponent.selector,
		data: {
          requiredAuth: false
        }
      })
      .state(AppComponent.selector, {
        url: `/${AppComponent.selector}`,
        component: AppComponent.selector,
        data: {
          requiredAuth: true
        }
      })
	  .state(CalendarioComponent.selector, {
        url: `/${CalendarioComponent.selector}`,
        component: CalendarioComponent.selector,
		parent: AppComponent.selector,
        data: {
          requiredAuth: true
        }
      });
	  

    $locationProvider.hashPrefix('');
    $locationProvider.html5Mode(true);

    $urlRouterProvider.otherwise(LoginComponent.selector);
  })
  .name;
  
  