
import 'bootstrap/scss/bootstrap.scss'
import 'angular-material/angular-material.css';

//services 
import EventService from '../../services/event/event.service';

export const CalendarioComponent = {
  selector: 'calendario',
  template: require('./calendario.component.html'),
  controller: class CalendarioController {
	
    constructor(filterFilter) {
      'ngInject';
	  this.CalendarioModel ={};
	  this.service = new EventService;
	  this.$filter = filterFilter; 
	  this.userList = [{
        email: 'user@email.com',
        password: 'secret'
      }];
	  
	  this.calendarView = 'day';

	  this.viewDate = new Date();
	  
    }
	

    $onInit() {
		this.eventSelected=false;
		let colorTypes = {
		  info: {
			primary: '#1e90ff',
			secondary: '#d1e8ff'
		  },
		  important: {
			primary: '#ad2121',
			secondary: '#fae3e3'
		  },
		  warning: {
			primary: '#e3bc08',
			secondary: '#fdf1ba'
		  },
		  inverse: {
			primary: '#1b1b1b',
			secondary: '#c1c1c1'
		  },
		  special: {
			primary: '#800080',
			secondary: '#ffe6ff'
		  },
		  success: {
			primary: '#006400',
			secondary: '#caffca'
		  }
		}
		
		this.calendarView = 'month';
		this.viewDate = new Date();
		this.eventList = this.service.loadDummy();
		this.eventList.forEach(function(element) {
		  switch(element.RISK_ASSESSMENT)
		  {
			   case 1:
			   case 2:
			   element.color = colorTypes.info;
			   break;
			   case 3:
			   element.color = colorTypes.warning;
			   break;
			   case 4:
			   case 5:
			   element.color = colorTypes.important;
			   break;
		  }
		  console.log(element);
		});
		this.CAB =   this.eventList.filter(p=>p.RISK_ASSESSMENT>=4);
		this.events = this.eventList;
    }
		
	$postLink(){
		this.viewDate = new Date();
		this.eventSelected=false;
	}
	
	mostrarTodos(){
		this.events = this.eventList;
		this.eventSelected=false;
	}
	
	mostrarAgenda(){
		
	}
	
	ocultar(){
		this.eventSelected=false;
	}
	
	mostrarCAB(){
		this.events = this.CAB;
		this.eventSelected=false;
	}
	
	mostrarColision(){
		
	}
	
	eventClicked(event){
		this.eventSelected=true;
		this.currentEvent= event;
	}
	
	
  }
};
